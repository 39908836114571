import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Helmet from "react-helmet"
import Facebook from "../../components/campaigns/checklist/Facebook"
import Google from "../../components/campaigns/checklist/Google"
import Hero from "../../components/campaigns/checklist/Hero"
import Report from "../../components/campaigns/checklist/Report"
import "../../components/campaigns/checklist/scss/index.scss"
import Footer from "../../components/Footer"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

const Checklist = () => {
  const intl = useIntl()

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "campaign-checklist",
        }}
      >
        <link rel="stylesheet" href="https://use.typekit.net/kho5gmu.css" />
      </Helmet>
      <SEO title={intl.formatMessage({ defaultMessage: "Improve your event visibility" })} />
      <Layout clean>
        <Hero />
        <Report />
        <Google />
        <Facebook />
        <Footer disableCallToAction />
      </Layout>
    </>
  )
}

export default Checklist
