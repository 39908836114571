import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import Image1 from "../../../../assets/images/campaigns/checklist/google-1.png"
import Image2 from "../../../../assets/images/campaigns/checklist/google-2.png"
import Image3 from "../../../../assets/images/campaigns/checklist/google-3.png"
import "./scss/index.scss"

const GoogleItem = ({ image, ...props }) => (
  <Image src={image} fluid className="position-relative bg-white shadow rounded p-3" {...props} />
)

const Google = () => {
  const intl = useIntl()

  return (
    <section className="google pt-10 pb-12">
      <Container>
        <h2 className="text-center mb-10">
          {intl.formatMessage({ defaultMessage: "What are we testing? ..." })}
        </h2>
        <Row className="align-items-center">
          <Col lg={6} className="mb-10 mb-lg-0">
            <h1
              className="heading mb-4"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    '<b class="text-primary">Structured data</b> to improve search results',
                }),
              }}
            />
            <p className="lead">
              {intl.formatMessage({
                defaultMessage:
                  "By providing explicit data about your event in a standardized format (structured data) you can expect the best possible representation in search results.",
              })}
            </p>
            <p className="lead mb-0">
              {intl.formatMessage({
                defaultMessage:
                  "This structured data needs to be present and setup correctly in the source code of your event page.",
              })}
            </p>
          </Col>
          <Col lg={{ span: 5, offset: 1 }} className="position-relative p-0">
            <Row className="w-lg-120 ml-n8 mb-4" noGutters>
              <Col xs={8}>
                <GoogleItem src={Image1} style={{ left: -10, bottom: 20 }} />
              </Col>
              <Col xs={4}>
                <GoogleItem src={Image2} style={{ right: -30, bottom: 50 }} />
              </Col>
            </Row>
            <GoogleItem src={Image3} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Google
