import classnames from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import Image1 from "../../../../assets/images/campaigns/checklist/facebook-create-post.png"
import Image2 from "../../../../assets/images/campaigns/checklist/facebook-post.png"
import "./scss/index.scss"

const FacebookItem = ({ image, className, ...props }) => (
  <Image
    src={image}
    fluid
    className={classnames("position-relative bg-white shadow rounded p-3", className)}
    {...props}
  />
)

const Facebook = () => {
  const intl = useIntl()

  return (
    <section className="facebook py-12">
      <Container>
        <Row className="align-items-center">
          <Col
            xs={{ span: 12, order: 2 }}
            lg={{ span: 5, order: 1 }}
            className="position-relative p-0 ml-n8"
          >
            <div className="facebook__create-post mb-8">
              <FacebookItem src={Image1} style={{ left: -10, top: 0 }} />
            </div>
            <FacebookItem src={Image2} style={{ right: -30, top: 0 }} />
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2, offset: 1 }}
            className="mb-8 mb-lg-0"
          >
            <h1
              className="heading mb-4"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    '<b class="text-primary">Boost</b> social media posts with <b class="text-primary">Open Graph</b> tags',
                }),
              }}
            />
            <p className="lead">
              {intl.formatMessage({
                defaultMessage:
                  "One important way to reach a wider audience is to share your event on social media. By using Open Graph tags your event post is optimized perfectly for social media.",
              })}
            </p>
            <p className="lead mb-0">
              {intl.formatMessage({
                defaultMessage:
                  "Open Graph meta tags let you take control over how your content appears on social media sites. This ensures the highest quality posts which are proven to increase click-throughs.",
              })}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Facebook
