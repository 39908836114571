import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import IconArrowRight from "../../../../assets/svg/campaigns/checklist/arrow-right.svg"
import ChangingProgressProvider from "./ChangingProgressProvider"
import "./scss/index.scss"

const ChecklistItem = ({ text }) => (
  <div className="d-flex align-items-center mb-1">
    <IconArrowRight className="mr-2" />
    <p className="small mb-0">{text}</p>
  </div>
)

const Report = () => {
  const intl = useIntl()

  return (
    <section className="report pb-8 pb-lg-10">
      <Container className="text-white">
        <Row className="justify-content-center">
          <Col lg={7} className="report__result rounded p-6 mx-6 mx-lg-0">
            <h3 className="text-center">
              {intl.formatMessage({ defaultMessage: "Generate the Report for your Event" })}
            </h3>
            <Row className="align-items-center mt-5">
              <Col lg={3} className="d-flex justify-content-center mb-5 mb-lg-0">
                <div className="report__score">
                  <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                    {percentage => (
                      <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        styles={buildStyles({
                          pathColor: "#00c58e",
                          textColor: "#fff",
                          trailColor: "#fff",
                        })}
                      />
                    )}
                  </ChangingProgressProvider>
                </div>
              </Col>
              <Col lg={9}>
                <div className="mb-n1">
                  <ChecklistItem
                    text={intl.formatMessage({
                      defaultMessage: "Calculated score based on different criteria",
                    })}
                  />
                  <ChecklistItem
                    text={intl.formatMessage({
                      defaultMessage: "Checks against established best practices",
                    })}
                  />
                  <ChecklistItem
                    text={intl.formatMessage({
                      defaultMessage: "Reveals the potential impact of your event page",
                    })}
                  />
                  <ChecklistItem
                    text={intl.formatMessage({
                      defaultMessage: "Automatic suggestions for improvement",
                    })}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Report
