import { useIntl } from "gatsby-plugin-intl"
import React, { useEffect, useState } from "react"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { CSSTransition } from "react-transition-group"
import { CHECKLIST } from "../../../../../campaign-config"
import IconCheck from "../../../../assets/svg/campaigns/checklist/c-check.svg"
import IconNotChecked from "../../../../assets/svg/campaigns/checklist/c-remove.svg"
import IconArrowDown from "../../../../assets/svg/icons/arrow-down.svg"
import LeadGeneration from "../LeadGeneration"
import "./scss/index.scss"

const ChecklistItem = ({ text, checked }) => (
  <Card className="shadow mb-4">
    <Card.Body className="p-3">
      <p className="d-flex align-items-center small mb-0">
        {checked ? (
          <IconCheck className="text-success mr-3" />
        ) : (
          <IconNotChecked className="text-danger mr-3" />
        )}
        {text}
      </p>
    </Card.Body>
  </Card>
)

const Hero = () => {
  const intl = useIntl()
  const [scrollY, setScrollY] = useState(0)

  function updateScrollY() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener("scroll", updateScrollY)

    return () => {
      window.removeEventListener("scroll", updateScrollY)
    }
  })

  return (
    <section className="hero position-relative">
      <Container className="pt-8 pt-lg-11 pb-13 pb-lg-14">
        <Row className="justify-content-center mb-8">
          <Col lg={10} className="text-center">
            <h1
              className="heading display-4 mb-2"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage: 'Improve your event <b class="text-primary">visibility</b>',
                }),
              }}
            />
            <p className="description mb-0">
              {intl.formatMessage({ defaultMessage: "Check your event page with one click." })}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={8} className="mb-n5">
            <div className="hero__checklist">
              <ChecklistItem
                text={intl.formatMessage({
                  defaultMessage: "Open Graph Tags for Facebook, Twitter",
                })}
                checked
              />
              <ChecklistItem
                text={intl.formatMessage({ defaultMessage: "Set title, description and tags" })}
                checked
              />
              <ChecklistItem
                text={intl.formatMessage({
                  defaultMessage: "Event Schema is available and setup correctly",
                })}
              />
              <ChecklistItem
                text={intl.formatMessage({ defaultMessage: "iCalendar is available for download" })}
                checked
              />
            </div>
          </Col>
        </Row>
        <CSSTransition in={scrollY <= 200} timeout={350} classNames="fade" unmountOnExit>
          <div className="hero__scroll-down d-none d-md-block text-center text-primary mb-11">
            <IconArrowDown className="bounce" />
          </div>
        </CSSTransition>
      </Container>
      <Container style={{ zIndex: 12 }}>
        <Card className="hero__lead-generation col-10 shadow px-0 mb-n12 mb-md-n7">
          <Card.Body className="p-4">
            <LeadGeneration campaign={CHECKLIST} />
          </Card.Body>
        </Card>
      </Container>
    </section>
  )
}

export default Hero
